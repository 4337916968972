import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    label: 'Home',
    isTitle: true
  },
  {
    label: 'Dashboard',
    icon: 'fas fa-tachometer-alt-average',
    link: '/dashboard'
  },
  {
    label: 'Facturación',
    isTitle: true
  }, 
  {
    label: 'Recibidas',
    icon: 'fas fa-inbox-in',
    link: '/invoices/income',
  },
  {
    label: 'Enviadas',
    icon: 'fas fa-inbox-out',
    link: '/invoices/outcome',
  },
  {
    label: 'Impagadas',
    icon: 'fas fa-exclamation-triangle',
    link: '/invoices/unpaid',
  },
  {
    label: 'Pendientes',
    icon: 'fas fa-alarm-clock',
    link: '/invoices/pending',
  },  
  {
    label: 'Tesorería',
    isTitle: true
  },
  {
    label: 'Conciliación bancaria',
    icon: 'fas fa-handshake-alt',
    link: '/banks/conciliation',
  },
  {
    label: 'Movimientos bancarios',
    icon: 'fas fa-money-check-edit',
    link: '/account-notes',
  },
  {
    label: 'Cuentas contables',
    icon: 'fas fa-wallet',
    link: '/accounts',
  },
  {
    label: 'Filtros',
    icon: 'fas fa-funnel-dollar',
    link: '/account-filters',
  },  
  {
    label: 'Laboral',
    isTitle: true
  },
  {
    label: 'Empleados',
    icon: 'fas fa-users',
    link: '/employees',
  },
  {
    label: 'Administración',
    isTitle: true
  },
  {
    label: 'Clientes',
    icon: 'fas fa-building',
    link: '/companies',
  },
  {
    label: 'Proveedores',
    icon: 'far fa-dolly-flatbed',
    link: '/providers',
  },
  {
    label: 'Impuestos',
    icon: 'fas fa-percentage',
    link: '/taxes',
  },
  {
    label: 'Bancos',
    icon: 'fas fa-piggy-bank',
    link: '/banks',
  },
  {
    label: 'Configuración',
    isTitle: true
  },
  {
    label: 'Sistema',
    icon: 'fas fa-tools',
    link: '/settings',
  }
];
