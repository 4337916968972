import { Routes } from '@angular/router';
import { BaseComponent } from '../views/layout/base/base.component';
import { AuthMiddleware } from '../middlewares/auth';
import { RoleMiddleware } from '../middlewares/role';


const routes: Routes = [
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthMiddleware, RoleMiddleware],
    data: {
      role: '1'
    },
    children: [      
      {
        path: 'invoices',
        loadChildren: () => import('../views/pages/invoices/invoices.module').then(m => m.InvoicesModule)
      },
      {
        path: 'account-notes',
        loadChildren: () => import('../views/pages/account-notes/account-notes.module').then(m => m.AccountNotesModule)
      },
      {
        path: 'accounts',
        loadChildren: () => import('../views/pages/accounts/accounts.module').then(m => m.AccountsModule)
      },
      {
        path: 'account-filters',
        loadChildren: () => import('../views/pages/account-filters/account-filters.module').then(m => m.AccountFiltersModule)
      },
      {
        path: 'companies',
        loadChildren: () => import('../views/pages/companies/companies.module').then(m => m.CompaniesModule)
      },
      {
        path: 'providers',
        loadChildren: () => import('../views/pages/providers/providers.module').then(m => m.ProvidersModule)
      },
      {
        path: 'taxes',
        loadChildren: () => import('../views/pages/taxes/taxes.module').then(m => m.TaxesModule)
      },
      {
        path: 'banks',
        loadChildren: () => import('../views/pages/banks/banks.module').then(m => m.BanksModule)
      },      
      {
        path: 'employees',
        loadChildren: () => import('../views/pages/employees/employees.module').then(m => m.EmployeesModule)
      },      
      {
        path: 'settings',
        loadChildren: () => import('../views/pages/settings/settings.module').then(m => m.SettingsModule)
      },
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
    ]
  },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthMiddleware],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('../views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      },      
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
    ]
  }
];

export const backofficeRoutes = routes;
