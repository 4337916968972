import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { generalRoutes } from './routes/general.routes';
import { backofficeRoutes } from './routes/backoffice.routes';


let routes:any = [];
routes = routes.concat(backofficeRoutes);
routes = routes.concat(generalRoutes);

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
