/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/member-ordering */
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, defer, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';

import axios from 'axios';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RestService {

  constructor(
    private http: HttpClient,
  ) { }

  apiUrl: any = environment.apiUrl;

  public static getApiUrl = (): string => environment.apiUrl;

  private handleError = (error: HttpErrorResponse): any => // if (error.error instanceof ErrorEvent) {
    //   // console.error('An error occurred:', error.error.message);
    // } else {
    //   // console.error(
    //   //   `Backend returned code ${error.status}, ` +
    //   //   `body was: ${error.error}`);
    // }
    throwError(error);

  private extractData(res: any): any {
    const body = res;
    return body || { };
  }

  get = (endpoint: string, options: any): Observable<any> => this.http.get(this.apiUrl + endpoint, options).pipe(
    map(this.extractData),
    catchError(this.handleError)
  );

  patch = (endpoint: string, data: any, options: any): Observable<any> => this.http.patch(this.apiUrl + endpoint, data, options).pipe(
    map(this.extractData),
    catchError(this.handleError)
  );

  post = (endpoint: string, data: any, options: any): Observable<any> => this.http.post(this.apiUrl + endpoint, data, options).pipe(
    map(this.extractData),
    catchError(this.handleError)
  );

  delete = (endpoint: string, data: any): Observable<any> => this.http.delete(this.apiUrl + endpoint, data).pipe(
    map(this.extractData),
    catchError(this.handleError)
  );

  // eslint-disable-next-line max-len
  postFile = (endpoint: string, data: any, options: any): Observable<any> => defer( () => axios.post(this.apiUrl + endpoint, data, { params: options, headers: { Authorization : 'Bearer ' + AuthService.getToken() } }))
    .pipe(
      map(result => result.data),
      catchError(this.handleError)
    );
  
  patchWithFile = (endpoint: string, data: any, options: any): Observable<any> => defer( () => axios.patch(this.apiUrl + endpoint, data, { params: options, headers: { Authorization : 'Bearer ' + AuthService.getToken() } }))
    .pipe(
      map(result => result.data),
      catchError(this.handleError)
    );

}
