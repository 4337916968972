import { Component, OnInit, ViewChild, ElementRef, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { RestService } from 'src/app/services/rest.service';
import { ErrorService } from 'src/app/services/error.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  user: any;
  userImage: any;
  invoiceQuery: any;
  toast: any;

  constructor(
    @Inject(DOCUMENT) private document: Document, 
    private renderer: Renderer2,
    private router: Router,
    private restService: RestService,
    private errorService: ErrorService,
    private i18n: TranslateService
  ) { }

  async ngOnInit(): Promise<void> {
    this.user = await AuthService.getUserObject();
    // console.log("User from navbar", this.user);
    const userImage = await AuthService.getUserImage();    
    if (userImage){
      this.userImage = userImage;
    }

    this.toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 4000,
      timerProgressBar: true,
    }); 
  }

  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e: Event) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  /**
   * Logout
   */
  onLogout(e: Event) {
    e.preventDefault();
    localStorage.removeItem(environment.localStorageFlagName);

    if (!localStorage.getItem(environment.localStorageFlagName)) {
      this.router.navigate(['/auth/login']);
    }
  }
  
  searchInvoice(): void{
    console.log("searching for invoice " + this.invoiceQuery);
    this.restService.post('/api/invoice/search', {
      invoiceQuery: this.invoiceQuery
    }, []).subscribe({
      next: (resp: any) => {            
        if (resp.data.invoice && resp.data.invoice.id){
          this.router.navigate(['/invoices/invoice-detail/'+resp.data.invoice.id]);
        }else{
          this.toast.fire({
            icon: 'error',
            title: this.i18n.instant('Invoice not found.')
          });
        }
      },
      error: (error: any) => {        
        this.errorService._error('warning', 'invoice - query() error', error.message);        
        this.toast.fire({
          icon: 'error',
          title: this.i18n.instant('Invoice not found.')
        });
      }
    });
  }

}
