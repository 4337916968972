import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) { }

  intercept = (request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> => next.handle(request).pipe(catchError(err => {
    if ([401, 403, 0].includes(err.status) && this.authService.isLoggedIn()) {
      // auto logout if 401 or 403 response returned from api
      this.authService.logout();
    }

    const error = (err && err.error && err.error.message) || err.statusText;    
    throw err;
  }));
}
