import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { AuthService } from './services/auth.service';
import { ErrorService } from './services/error.service';
import { RestService } from './services/rest.service';
import * as moment from 'moment';
import 'moment-timezone';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Kala';
  user: any;
  isLoading = false;
  toast: any;

  constructor(
    private router: Router,    
    private restService: RestService,
    private errorService: ErrorService,
    private authService: AuthService,
    private socialAuthService: SocialAuthService,
    private i18n: TranslateService
    ) { }

  ngOnInit(): void {
    this.toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 4000,
      timerProgressBar: true,
    });  

    moment.tz.setDefault('Etc/UTC');

    this.socialAuthService.authState.subscribe((user) => {
      this.user = user;
      // console.log('SOCIAL AUTH STATE CHANGE! ', this.user);
      this.isLoading = true;
      if (this.user && this.user.email){
        this.restService.post('/api/auth/googleLogin', {idToken : this.user.idToken}, []).subscribe({
          next: (resp: any) => {            
            this.isLoading = false;
            this.authService.login(resp.data);
            this.router.navigate(['/dashboard']);
          },
          error: (error: any) => {
            console.log('ERROR LOGIN!!!', error);
            this.isLoading = false;
            this.errorService._error('warning', 'login error', error.message);
            this.toast.fire({
              icon: 'error',
              title: error.error.error
            });          
          }
        });
      }else{
        this.isLoading = false;
        this.router.navigate(['/auth/login/', true]);
      }
    });
  }

}
