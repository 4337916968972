import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Injectable()
export class RoleMiddleware implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
    ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean{
    const role = route.data.role;
    if (role && this.authService.hasUserRole(Number(role))) {
      // role
      return true;
    }

    const roles = route.data.roles;
    if (roles && roles.length > 0){
      for (const rol of roles){
        if (this.authService.hasUserRole(Number(rol))){
          return true;
        }
      }
    }
    // not logged in so redirect to login page with the return url
    // this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
    this.router.navigate(['/auth/login']);
    return false;
  }
}
