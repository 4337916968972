import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private router: Router,
    ) { }

  apiUrl: any = environment.apiUrl;
  endpoint: any;

  public static getToken(): string | false{
    if (localStorage.getItem(environment.localStorageFlagName) && localStorage.getItem('token')){
      return String(localStorage.getItem('token'));
    }
    return false;
  }

  public static getUserObject(): any{
    if (localStorage.getItem(environment.localStorageFlagName) && localStorage.getItem('user')){
      return JSON.parse(String(localStorage.getItem('user')));
    }
    return false;
  }

  public static getUserImage(): any{
    if (localStorage.getItem(environment.localStorageFlagName) && localStorage.getItem('userImage')){
      return String(localStorage.getItem('userImage'));
    }
    return false;
  }

  public static getUserId(): string | false{
    if (localStorage.getItem(environment.localStorageFlagName) && localStorage.getItem('userId')){
      return String(localStorage.getItem('userId'));
    }
    return false;
  }

  public static setUserObject(user: any): void{
    if (user){
      localStorage.setItem('user', JSON.stringify(user));
    }
  }

  public hasUserRole(roleId: any): boolean{
    let mRet = false;
    if (localStorage.getItem(environment.localStorageFlagName) && localStorage.getItem('user')){
      const user = JSON.parse(String(localStorage.getItem('user')));      
      if (user.roles && user.roles.length > 0 && user.roles.filter((role: any) => {
        return role.id === roleId;
      }).length > 0){
        mRet = true;
      }
    }    
    return mRet;
  }

  isLoggedIn(): boolean{
    return (localStorage.getItem(environment.localStorageFlagName)) ? true : false;
  }

  login(data: any): void{
    // console.log("auth service login with data ", data);
    localStorage.setItem(environment.localStorageFlagName, 'true');
    localStorage.setItem('userId', data.user.id);
    localStorage.setItem('token', data.user.token);
    localStorage.setItem('roleId', data.user.roleId);
    localStorage.setItem('user', JSON.stringify(data.user));

    // Set user photo from employee
    // console.log("employees", data.employees);
    if (data.employees && data.employees.length > 0){
      const employee = data.employees.find((employee: any) => employee.email === data.user.email);      
      if (employee){
        localStorage.setItem('userImage', employee.image);      
      }
    }
  }

  logout(): void{
    localStorage.removeItem('token');
    localStorage.removeItem(environment.localStorageFlagName);
    localStorage.removeItem('userId');
    localStorage.removeItem('roleId');
    localStorage.removeItem('userImage');
    this.router.navigate(['/auth/login']);
  }

}
