// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'http://localhost:3200',  
  // apiUrl: 'https://kala-api-dev.armadilloamarillo.com',  
  localStorageFlagName: 'kala_lld02jmma1',
  monarApp: 'Kala',
  monarAppId: 'X',
  monarConsole: '1',
  currencySimbol: '€',
  dateTimeFormat: 'DD/MM/YYYY HH:mm',
  dateFormat: 'DD/MM/YYYY',
  dateCommentsFormat: 'DD-MM-YYYY',
  timeCommentsFormat: 'HH:mm',
  google_client_id: '24903044211-khj0a1rsbsgpr2gri9ag2i3e70phfrqa.apps.googleusercontent.com',
  google_client_secret: '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',
  appName: 'Kala'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
